@font-face {
  font-family: "ApexNew";
  font-style: normal;
  font-weight: 400;
  src:
    local("ApexNew-Book"),
    url("../fonts/ApexNew-Book.woff") format("woff");
}

@font-face {
  font-family: "ApexNew-medium";
  font-style: normal;
  font-weight: 600;
  src:
    local("ApexNew-Medium"),
    url("../fonts/ApexNew-Medium.woff") format("woff");
}

@font-face {
  font-family: "ApexNew-bold";
  font-style: normal;
  font-weight: 700;
  src:
    local("ApexNew-Bold"),
    url("../fonts/ApexNew-Bold.woff") format("woff");
}

@font-face {
  font-family: "Bodini-smallcaps";
  font-style: normal;
  font-weight: 100;
  src:
    local("Bodoni Seventytwo ITC"),
    url("../fonts/bodoni-seventytwo-itc-book-sc.woff") format("woff");
}

@font-face {
  font-family: "ColemansHand";
  font-style: normal;
  src:
    url("../fonts/ColemansHand_Regular.woff") format("woff"),
    url("../fonts/ColemansHand_Regular.ttf") format("ttf");
}
