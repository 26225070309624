#resources {
  .accordion-2 {
    .card {
      color: $teal;
      background-color: $grey-7;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      position: relative;
      border: none;
      border-radius: 0;
      flex-grow: 1;
      box-shadow: 0px 5px 13px 0px $light-transparent;
      height: 100%;

      @include media-breakpoint-up(lg) {
        margin: 20px 29px 0 0;
      }

      .card-header {
        background-color: $white;
        border-bottom: 0px solid transparent;
        padding: 0px;
        margin: 0px;

        button {
          min-height: 65px;
          height: auto;
          width: 100%;
          display: block;
          padding: rem-calc(18) rem-calc(34) rem-calc(18) rem-calc(18);
          background-color: transparent;
          border: none;

          span:after {
            background-image: url("../images/icon-plus-grey.svg");
            height: 12px;
            width: 12px;
            right: -1.15em;
          }

          &[aria-expanded="true"] span::after {
            background-image: url("../images/icon-minus-grey.svg");
          }
        }

        h4 {
          color: $blue;
          text-transform: initial;
          pointer-events: none;
          margin-bottom: 0;

          span {
            width: 100%;
          }
        }
      }

      .card-body {
        color: $grey-4;
        padding: rem-calc(18);

        .btn-link {
          color: $blue;
          font-family: "ApexNew-Bold", Helvetica, Arial, sans-serif;
          border: none;
          text-transform: none;
          text-decoration: underline !important;
          font-size: 16px;
          padding: 0;
          margin: 0;
        }

        ul li:after {
          background-color: $grey-4;
        }

        a {
          font-family: $font-bold;
          color: $blue;
          text-decoration: underline !important;
          &:hover {
            color: $black !important;
          }
        }
      }
    }
  }
}
