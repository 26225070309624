// Modals

.modal-header,
.modal-body,
.modal-footer {
  border-top: 0px solid transparent;
  border-bottom: 0px solid transparent;
}

#exit-modal {
  display: none;
}

[data-modal] {
  align-items: flex-start !important;

  ul li ul li {
    margin-bottom: 10px;
  }
}

$close-button-size: 34px;

[data-modal] > div {
  display: flex;
  position: relative;
  margin-top: 8%;
  width: 60%;
  max-width: 535px;
  box-shadow: 0px 11px 76.44px 21.56px $dark-transparent;
  padding: 20px;

  @include media-breakpoint-up(md) {
    max-height: calc(100% - 350px);
  }

  a {
    color: $body-color;
    text-decoration: underline;
  }
  @include media-breakpoint-down(sm) {
    width: calc(100% - #{$close-button-size});
  }

  aside {
    padding: 20px;
    margin: 0 auto;
    @include media-breakpoint-down(sm) {
      padding: 25px;
    }
  }

  p {
    margin-bottom: 1rem;

    &:last-of-type {
      margin: 0;
    }
  }
}

[data-modal="exit-modal"] {
  & > div {
    text-align: center;
    max-width: 538px;
    max-height: 100%;
  }
  & > div > aside {
    padding-left: 45px;
    padding-right: 45px;

    @include media-breakpoint-down(sm) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  h2 {
    font-size: rem-calc(30);
    text-transform: uppercase;
  }
  p {
    text-align: left;
  }
}
