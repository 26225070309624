html,
body {
  background-color: #fff;
  color: $body-color;
  font-family: $font-book;
  letter-spacing: 0.01rem;
  margin: 0;
  padding: 0;
  line-height: 1.3;
  font-variant-numeric: lining-nums;
  font-size: 14px;
  min-width: 375px;

  @include media-breakpoint-up(md) {
    font-size: 16px;
  }
}

a {
  text-decoration: none;

  &:focus,
  &:hover {
    color: inherit;
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  border: 0;
  background: none;
  outline: none;
  cursor: pointer;
  appearance: none;

  &:focus {
    outline: none;
  }
}
