#care-partners {
  #masthead p {
    max-width: 310px;
  }
  #care-partners-carousel {
    overflow: visible;

    @include media-breakpoint-up(md) {
      min-height: 476px;
    }
  }

  .carousel {
    .image-wrapper {
      margin: 3em auto 0;

      @include media-breakpoint-up(md) {
        width: 80%;
        min-height: 320px;
      }

      img {
        width: 100%;
      }
    }

    .carousel-control-icon {
      background-image: url("../images/icon-side-arrow.svg");
      width: 36px;
      height: 29px;
    }

    .carousel-control {
      z-index: 999;
      top: 8%;
      position: absolute;
      display: none;

      @include media-breakpoint-up(md) {
        display: flex;
      }
    }

    .carousel-control-prev-icon {
      transform: scaleX(-1);
    }

    .carousel-control-prev {
      left: -3em;
    }

    .carousel-control-next {
      right: -3em;
    }

    .carousel-indicators {
      width: 100%;
      margin: 0;
      bottom: 0;
      left: 0;

      li {
        margin-left: 0;
      }

      button {
        padding: 10px;

        &.active:before {
          background-color: $teal;
        }

        &:before {
          height: 10px;
          width: 10px;
          border-radius: 50px;
          background-color: $grey-1;

          @include media-breakpoint-down(sm) {
            top: 50px;
          }
        }
        &:after {
          display: none;
        }
      }
    }
  }

  .tips .grid > div {
    padding: 0 2em;
  }

  @include media-breakpoint-up(md) {
    #article-3 .icon-wrapper {
      order: 1;
    }
  }

  .care-partners-comments {
    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-around;
    }

    li {
      height: 150px;
      width: 200px;
      background: url("../images/care-partners-comment-bubble.svg") no-repeat center;
      font-family: $font-bold;
      color: $blue;
      margin: 2em auto 0;
      position: relative;

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -87%);
        width: 80%;
      }
    }
  }

  .numbered-list {
    padding-left: 2em;
    position: relative;
    margin-bottom: 2em;

    &:before {
      position: absolute;
      left: 0;
      font-size: rem-calc(35);
      color: $blue;
      top: -5px;
    }

    &:nth-of-type(1)::before {
      content: "1";
    }

    &:nth-of-type(2)::before {
      content: "2";
    }

    &:nth-of-type(3)::before {
      content: "3";
    }
  }

  .care-partners-checkbox li {
    background: url("../images/icon-checkmark-dark.svg") no-repeat left center;
    max-width: 420px;
    background-size: 30px;
    font-family: $font-medium;
    color: $blue;
    margin: 10px auto;
    padding-left: 40px;
    height: 45px;
    display: flex;
    align-items: center;
  }
}
