main#about-ongentys {
  .tab-group {
    button {
      background-color: transparent;
      color: $blue;

      &.active,
      &:hover {
        background-color: $green;
        color: $blue;
        border-color: $green;
      }

      span {
        text-align: center;
        pointer-events: none;
        line-height: 1.2;
      }
    }
  }

  .learn-more {
    position: relative;
    text-align: center;

    &:after {
      content: "";
      height: 70px;
      position: absolute;
      top: rem-calc(26);
      left: 50%;
      z-index: 1;
      width: 100px;
      transform: translate(-50%, 0);
      background: url("../images/down-arrow.svg") no-repeat center;
    }
  }
}
