@import "bootstrap/bootstrap";
@import "vars";
@import "mixins";
@import "reset";
@import "display";
@import "spacing";
@import "fonts";
@import "type";
@import "spacing";
@import "buttons";
@import "tabs";
@import "modals";
@import "template";
@import "callout";
@import "global";
@import "notifications";
@import "footer";
@import "isi";
@import "references";
@import "navigation";
@import "pages/home";
@import "pages/about-ongentys";
@import "pages/taking-ongentys";
@import "pages/care-partners";
@import "pages/support.scss";
@import "pages/resources.scss";
@import "pages/sitemap.scss";
@import "pages/404.scss";
@import "pages/redirect.scss";
@import "pages/stay-updated.scss";
@import "pages/contact-a-rep.scss";

button,
input,
select,
textarea {
  font-family: $font-book;
  font-variant-numeric: lining-nums;
}
main {
  position: relative;
  z-index: 1;
  @include media-breakpoint-up(md) {
    padding-top: 0;
  }
}

// Responsive
@include media-breakpoint-down(sm) {
  .desktop {
    display: none !important;
  }
}
@include media-breakpoint-up(md) {
  .mobile {
    display: none !important;
  }
}

// BASE
p,
ul {
  margin-bottom: 10px;
}

.no-wrap {
  white-space: nowrap;
}

.list li {
  position: relative;
  margin: 10px 0;
  padding: 0 0 0 1.5em;

  &::after {
    content: "";
    position: absolute;
    top: 6px;
    left: 10px;
    display: block;
    height: 5px;
    width: 5px;
    border-radius: 50px;
    background-color: $grey-4;
  }

  ul {
    margin: 10px 0;

    li {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  li::after {
    top: 10px;
    height: 1px;
    width: 6px;
    background-color: $body-color;
  }
}

.list-green li::after {
  background-color: $green;
}

.hidden {
  display: none;
}

.visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  visibility: hidden;
  opacity: 0;
}

// FLEX
.flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.center {
  align-items: center;
  justify-content: center;
}

.align-center {
  align-items: center;
}

.just-center {
  justify-content: center;
}

.flex-right {
  align-items: flex-end;
}

.flex-bottom {
  justify-content: flex-end;
}

.space-between {
  justify-content: space-between;
}
