$callout-spacing: 8px;

.callout {
  position: relative;
  min-height: 110px;
  display: flex;
  padding: 1.75em 1em;

  @include media-breakpoint-up(md) {
    display: flex;
    align-items: center;
    padding: 0 0 0 0.5em;
    min-height: 90px;
  }

  .border {
    width: 100%;
    height: 100%;
    position: absolute;
    left: $callout-spacing * -1;
    top: $callout-spacing * -1;
  }

  .callout-text {
    color: $blue;
    font-size: rem-calc(18);
    margin-top: $callout-spacing * -1;

    @include media-breakpoint-up(md) {
      padding-left: 0.5em;
    }
  }

  &-main {
    background-color: $light-blue;

    .border {
      border: 2px solid $teal;
    }
  }

  &-action {
    background-color: #e8eff5;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    .border {
      border: 2px solid $blue;
    }

    .callout-button {
      @include media-breakpoint-up(md) {
        margin-right: 1.5em;
        margin-top: $callout-spacing * -1;
      }

      span {
        position: relative;
        border: 1px solid $blue;
        background-color: $blue;
        color: $white;
        font-size: rem-calc(13);
        font-family: $font-medium;
        padding: 10px 50px 8px 18px;
        display: block;
        width: 250px;

        &:after {
          content: "";
          background: url("../images/icon-button-arrow-white.svg") no-repeat;
          background-size: contain;
          width: 16px;
          height: 12px;
          position: absolute;
          right: 12px;
          top: 10px;
        }
      }

      &:hover span {
        background-color: $white;
        color: $blue;

        &:after {
          background: url("../images/icon-button-arrow-dark-blue.svg") no-repeat;
        }
      }
    }
  }
}
