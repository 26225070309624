.button {
  display: inline-flex;
  align-items: center;
  min-width: 160px;
  height: 48px;
  white-space: normal;
  border: 1px solid $green;
  background-color: $green;
  color: $blue;
  font-family: apexnew-medium;
  text-transform: uppercase;
  text-align: left;
  font-size: rem-calc(12);
  padding: rem-calc(12) rem-calc(10) rem-calc(10);
  margin-right: 5px;
  cursor: pointer;

  &.active,
  &:hover {
    background-color: transparent;
    color: $teal;
  }

  span {
    width: 95%;
    display: block;
    position: relative;
    pointer-events: none;

    &:after {
      content: "";
      background: url("../images/icon-button-arrow-dark-blue.svg");
      background-repeat: no-repeat;
      background-size: contain;
      height: 20px;
      width: 20px;
      position: absolute;
      right: 4px;
      top: 50%;
      transform: translate(0, -37%);
    }
  }

  &.button-small {
    min-width: 145px;
  }

  &.link {
    background-color: transparent;
    border: none;
    text-transform: none;
    color: $blue;
    padding: 0;
    margin: 0;
    font-size: rem-calc(18);
    font-family: $font-bold;
  }

  &.no-arrow span:after {
    display: none;
  }

  &.button-exit {
    background-color: $white;
    width: 251px;
    font-size: rem-calc(16);
    text-decoration: none;
    color: $grey-4;
    border: 1px solid $grey-1;
    padding-top: rem-calc(12);

    span:after {
      background-image: url("../images/icon-button-arrow-grey.svg");
    }

    &:hover {
      background-color: $teal;
      color: $white;

      span:after {
        background-image: url("../images/icon-button-arrow-white.svg");
      }
    }
  }

  &.button-close {
    background: transparent;
    position: absolute;
    height: 15px;
    width: 15px;
    min-width: auto;
    background-image: url("../images/icon-close_x.svg");
    background-repeat: no-repeat;
    background-size: cover;
    border: 0px;
    top: 20px;
    right: 20px;

    &:hover {
      color: $black;
      border: 1px solid $blue;
    }
  }
}

.tooltip-wrapper {
  position: relative;

  button.tooltip-trigger {
    font-family: $font-bold;
    position: relative;
    text-decoration: underline;
    padding: 0px;
    color: $blue;
    margin-right: -3px;

    &:hover {
      background-color: #b6d0cf;
    }
  }
}

.tooltip {
  background-color: $white;
  display: none;
  font-family: ApexNew;
  font-size: rem-calc(16);
  width: 150px;
  color: $grey-4;
  padding: rem-calc(20);
  box-shadow: 0px 9px 16px -2px $dark-transparent;
  text-align: center;
  transform: none !important;
  z-index: 99;
  margin-top: 20px !important;

  @include media-breakpoint-up(md) {
    width: 250px;
  }

  &[data-show] {
    display: inline-block;
  }
}
