.tab-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 34px 0 38px;
}

.tab {
  position: relative;
  border: none;
  width: 226px;
  height: 50px;
  text-transform: uppercase;
  border-radius: 0;
  border: 1px solid #6bc8c7;
  background: transparent;
  font-size: rem-calc(12);
  margin-right: 1px;
  padding-top: 13px;
  cursor: pointer;

  &:nth-child(even) {
    margin-left: 5px;
  }

  &.active {
    outline: none;
  }
}

.tab-content {
  display: none;
  &.active {
    display: block;
  }
}
