$form-border: 1px solid $grey-1;
$selection-width: 400px;
$form-color: #707070;
$inputHeight: rem-calc(40);

#stay-updated {
  h2 {
    max-width: 100%;
  }

  form {
    @include media-breakpoint-down(sm) {
      .form-columns-3 div {
        width: 100% !important;
      }
    }

    fieldset {
      max-width: 100% !important;
      margin-bottom: 1em !important;
    }

    ul li {
      padding-left: 0;

      &::marker {
        content: "";
      }
    }

    a {
      @extend .link;
    }

    .field {
      position: relative;
      padding: 0.5em 0;

      label {
        display: block;
        font-family: $font-bold;
        margin-bottom: 0.25rem;
      }

      input {
        background: none;
        border: $form-border;
        height: $inputHeight;
        padding: 0.4em;
        width: 100%;

        &:focus {
          outline: none;
          border: 1px solid $teal;
        }
      }

      select {
        color: $form-color;
        width: 100%;
        border: $form-border;
        padding: 0.4em;
        outline: none;
        height: $inputHeight;
        font-size: 1rem;

        &:focus {
          border: 1px solid #008484;
        }
      }

      ::placeholder {
        color: $form-color;
        font-size: 1rem;
      }
    }

    .inputs-list li {
      label {
        position: relative;
        font-family: $font-book;
        padding-left: 3em;
        height: 2rem;

        &.hs-main-font-element {
          padding-left: 0;
        }
      }

      input {
        width: 2rem !important;
        height: 2rem;
        position: absolute;
        left: 0;
      }

      span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .hs-error-msgs li {
      margin-top: 0.5em;

      .hs-error-msg {
        font-size: rem-calc(12);
        color: $red;
        padding-left: 0;
      }
    }

    .hs-submit .hs-button {
      @extend .button;

      height: 2.5rem !important;
      color: $blue;
      padding-left: 1em;
      font-size: rem-calc(18);
      font-family: $font-bold;
    }
  }
}
