// Template
#page-content {
  background-color: $white;
  min-height: 100%;

  .container {
    margin-top: rem-calc(38);
    margin-bottom: rem-calc(38);
  }

  h2 {
    max-width: $smallWidth;
    margin-left: auto;
    margin-right: auto;
  }
}

article {
  padding: 10px 0;
}

#page-banner.banner {
  background-color: $light-blue;
  height: 120px;
  position: relative;

  h1 {
    font-size: rem-calc(34);
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
}

// Page Banners
#masthead {
  display: flex;
  align-items: center;
  height: 120px;
  position: relative;
  box-shadow: $shadow;

  .container {
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    box-shadow: none;
    height: 190px;

    .container {
      width: 340px;
    }
  }

  h1 {
    max-width: 370px;
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    padding-left: rem-calc(55);
    transform: translate(0, -50%);
    margin-top: 0;
  }

  @include media-breakpoint-up(md) {
    background: $white url("../images/inside-couple.png") no-repeat left center;
    background-size: contain;
    max-width: 1200px;
    margin: 0 auto;

    h1 {
      left: 40%;
      max-width: 400px;
    }
  }
}

.slanted-background {
  padding: rem-calc(28) 0;
  background-color: $off-blue;
  clip-path: polygon(0 3%, 100% 0%, 100% 97%, 0% 100%);

  @include media-breakpoint-up(md) {
    clip-path: polygon(0 5vh, 100% 0%, 100% 90%, 0% 100%);
  }
}

.text-align-center {
  text-align: center;
}
