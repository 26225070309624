footer#main-footer {
  background-color: $light-blue;
  color: $grey-4;

  .container {
    position: relative;
  }

  p {
    font-size: rem-calc(11);
  }

  .amneal-logo {
    width: 120px;

    @include media-breakpoint-up(md) {
      position: absolute;
      left: 1rem;
      top: 0;
    }
  }

  .bial-logo {
    height: 15px;
    margin: -4px 2px 0;
  }

  .stay-updated {
    text-align: center;
  }

  @include media-breakpoint-up(md) {
    .footer-content {
      padding-right: 0;
      padding-left: calc(8% + 115px);
    }

    .stay-updated {
      order: 3;
    }
  }

  .nav {
    display: list-item;
    margin-bottom: rem-calc(15);

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: flex-start;
    }

    li {
      margin-bottom: rem-calc(0);
      margin-right: rem-calc(20);
    }

    a {
      color: $grey-4;
      font-size: rem-calc(12);
      text-transform: uppercase;

      &:focus,
      &:hover {
        color: $teal;
        text-decoration: underline;
      }
    }
  }
}
