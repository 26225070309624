$grey-1: #b7b7b7; // btn-outline outline, text input outline
$grey-2: #ececea; // Nav and homepage cards bg
$grey-3: #898989; // small button background:
$grey-4: #4c4c4c; // text of btn-outline // body text
$grey-6: #757575;
$grey-7: #f6f7f7;

$black: #000000;
$white: #ffffff;

$dark-transparent: rgba(0, 0, 0, 0.24);
$dark-transparent-1: rgba(0, 0, 0, 0.075);
$dark-transparent-2: rgba(0, 0, 0, 0.75);

$light-transparent: rgba(172, 172, 172, 0.57);

$teal: #007372;
$green: #6bc8c7;
$dark-green: #009f9e;
$light-green: #d1f0f0;

$blue: #05498c;
$light-blue: #e8f7f7;
$off-blue: #f0f9f9;
$red: #eb0c00;
$off-white: #f3f2ec; // Background color

$body-color: $grey-4;

// BORDER RADIUS
$card-radius: 20px;

$shadow: 0 1px 5px 0 $dark-transparent;

// Font weights
$font-book: "ApexNew", Helvetica, Arial, sans-serif;
$font-medium: "ApexNew-Medium", Helvetica, Arial, sans-serif;
$font-bold: "ApexNew-Bold", Helvetica, Arial, sans-serif;

$secondaryNavHeight: 75px; // top links
$secondaryNavHeightDesktop: 60px;
$mainNavHeight: 70px; // main navigation
$cookiePolicyBannerH: 40px;

$mobileNavHeight: 65px;

//Scrolled
$scrollMainHeaderH: $mainNavHeight + $secondaryNavHeightDesktop;

$notificationHeight: 32px;
$notificationHeightMobile: 82px;
$notificationHeightMobileScrolled: 40px;

$margin-lg: 68px;

.display-inline-block {
  display: inline-block;
}

.underline {
  text-decoration: underline;
}

.background-lite-teal {
  background-color: $light-blue;
}

.background-lite-green {
  background-color: $light-green;
}

.background-gradient-dark {
  background: linear-gradient(135deg, $blue 0%, $teal 80%);
}

.small-caps {
  font-variant: all-small-caps;
  vertical-align: top !important;
}

.icon-small {
  width: 22px;
  height: 22px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 5px;
}

$maxWidth: 1080px;
$mediumWidth: 900px;
$smallWidth: 800px;
