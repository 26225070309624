h1,
h2 {
  font-family: $font-book;
}

h1 {
  font-size: rem-calc(20);
  margin: 10px 0 0;
  line-height: 1.3;
}

h2,
.h2 {
  font-size: rem-calc(34);
  color: $blue;
  letter-spacing: 1.5px;
  margin-bottom: 1.15em;
  line-height: 1.19;
}

h3,
.h3 {
  color: $teal;
  font-size: rem-calc(18);
  margin-bottom: 1.15em;
  font-family: $font-medium;
}

p {
  color: $grey-4;
  margin-bottom: 2em;
}

h4 {
  font-size: 1rem;
  font-family: $font-bold;
  letter-spacing: 0.5px;
}

sup {
  font-size: 70%;
  top: -0.5em;
  letter-spacing: 1px;

  &.dagger {
    font-size: 80%;
    top: -0.3em;
  }
}

strong {
  font-family: $font-bold;
}

small,
.small {
  font-size: rem-calc(12);
}

.lead {
  font-size: rem-calc(18);
  color: $blue;
  font-family: $font-medium;
}

.apexnew-book {
  font-family: ApexNew;
}

.colemanshand {
  font-family: ColemansHand;
}

.font-medium {
  font-family: $font-medium;
}

.font-bold {
  font-family: $font-bold;
}

.footnote {
  font-size: rem-calc(12);
  line-height: 1.1;
}

.hang {
  display: inline-block;
  text-indent: -5px;
}

.text-nowrap {
  white-space: nowrap;
}

.capitalize-case {
  text-transform: capitalize;
}

.color-blue {
  color: $blue;
}

.color-red {
  color: $red;
}

.color-teal {
  color: $teal !important;
}

.color-dark-green {
  color: $dark-green;
}

.text-medium {
  font-size: rem-calc(18);
}

.text-small {
  font-size: rem-calc(14);
}
