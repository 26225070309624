// ISI

.isi-el {
  .bottom-nav-mobile {
    display: none !important;
  }
}

#mobile-cookie-policy {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

body {
  @include media-breakpoint-up(md) {
    [data-isi-clone="collapsed"],
    [data-isi-clone="default"] {
      @media (max-width: 365px) {
        height: 93px !important;
      }
    }
  }
}

body.show-isi {
  [data-isi-clone],
  &.scrolled [data-isi-clone] {
    visibility: visible;
  }
}

@include media-breakpoint-down(sm) {
  body.scrolled [data-isi-clone],
  body.scrolled .bottom-nav-mobile {
    visibility: hidden;
  }
  body.show-isi .bottom-nav-mobile {
    visibility: hidden;
  }
  body [data-isi-clone],
  body .bottom-nav-mobile {
    visibility: visible;
    .mobile {
      padding-top: 30px;
    }
  }
  body.scrolled [data-isi-clone],
  body.scrolled .bottom-nav-mobile {
    @include media-breakpoint-down(md) {
      visibility: visible;
    }
  }
}

body.scrolled .isi {
  visibility: "visible";
}

.isi {
  box-shadow: 0 0 10px $dark-transparent-1;
  padding: 0 0 40px;
  transition-property: height;
}

.isi-container {
  a {
    text-decoration: underline;
  }

  ul li {
    margin: 3px 0;
  }

  .ml-10 {
    margin-left: 10px;
  }
}

.isi-toggle-container {
  height: 23px;
  font-size: 12px;
  display: none;

  .buttons {
    position: relative;
    display: flex;
    justify-content: flex-end;

    .isi__toggle-button {
      font-size: 13px;
      cursor: pointer;
      padding-right: 18px;
      padding-left: 20px;
      z-index: 99;
      color: $blue;
      position: relative;

      &::after {
        content: "^";
        position: absolute;
        right: 5px;
      }

      &.expand-button::after {
        top: 0px;
      }

      &.close-button::after {
        transform: rotate(180deg);
        bottom: 8px;
      }
    }
  }
}

.float-bg-blue {
  display: none;
}

[data-isi-clone] {
  @include media-breakpoint-down(sm) {
    position: relative;
  }

  .isi-toggle-container {
    display: block;
  }
}

[data-isi-clone="expanded"] {
  z-index: 5 !important;
  @include media-breakpoint-down(sm) {
    overflow-y: scroll;

    .isi-container {
      display: block;
    }
  }

  .isi-column {
    display: block;
    width: 100%;

    &.isi-column-2 {
      margin-top: 20px;
    }
  }

  .close-button {
    display: block;
  }

  .expand-button {
    display: none;
  }

  .float-collapse {
    display: none;
  }

  .float-default {
    margin-top: -25px;
    @media (max-width: 490px) {
      margin-top: 0px;
    }
  }
}

[data-isi-clone="collapsed"],
[data-isi-clone="default"] {
  .not-in-collapse {
    display: none;
  }
}

[data-isi-clone="collapsed"] {
  height: 100px !important;

  .close-button {
    display: none;
  }
  .expand-button {
    display: block;
  }

  .float-bg-blue {
    display: block;
    background-color: $light-blue;
  }
}

[data-isi-clone="default"] {
  height: 130px !important;

  .float-bg-blue {
    display: block;
    background-color: $light-blue;
  }

  .close-button {
    display: none;
  }
}

.desktop-browser {
  @include media-breakpoint-down(sm) {
    [data-isi-clone] {
      z-index: 101 !important;
    }
  }
}
