#home {
  .jumbotron {
    background: $white url("../images/Joseph-couple.png") no-repeat;
    background-position: left top;
    background-size: 800px;
  }

  #banner-text {
    position: relative;
    height: 100%;
    margin: 0 auto;

    .headline {
      padding-top: 180px;

      .headline-wrapper {
        background-color: $white;
        padding: 0 1rem 1rem;
        box-shadow: 7px -25px 25px $white;
      }

      .jumbo-headline {
        font-family: Bodini-smallcaps;
        font-size: rem-calc(42);
        line-height: 0.9;
      }
    }

    .inspired-by {
      color: $grey-4;
      position: absolute;
      left: 1rem;
      top: 150px;
    }

    .choice {
      max-width: 200px;
      margin: 1.75rem auto;
    }

    // check box and the "or" stylings
    .copy {
      display: inline-block;
      font-size: rem-calc(18);
      padding-left: rem-calc(45);
    }

    .or-divider-block {
      font-size: rem-calc(22);
      font-family: Bodini-smallcaps;
      position: relative;
      text-align: center;

      &:before,
      &:after {
        content: "";
        background: $grey-3;
        position: absolute;
        width: 20px;
        height: 1px;
        top: 11px;
      }

      &:before {
        left: 28%;
      }

      &:after {
        right: 28%;
      }
    }

    .choice-is-yours-text {
      font-size: rem-calc(24);
      margin: 0.75em 0;
    }
  }

  .control-symptoms-section {
    h1 {
      text-align: center;
      font-size: rem-calc(34);
    }

    .cta > div {
      a {
        display: block;
        margin: 0 auto;
        max-width: 160px;
        padding: 0.5em;
        position: relative;

        p {
          position: relative;
          z-index: 1;
        }

        &:hover {
          &:before,
          &::after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
          }

          &:before {
            border: 1px solid $blue;
            top: 0;
            left: 0;
            z-index: 1;
          }

          &:after {
            background-color: $white;
            left: 5px;
            top: 5px;
          }
        }

        img {
          width: 35px;
          height: 15px;
          margin-top: -3px;
        }
      }

      &:nth-child(2) {
        border-top: 1px solid $grey-3;
        border-bottom: 1px solid $grey-3;

        a {
          margin-top: 1em;
          margin-bottom: 1em;
        }

        @include media-breakpoint-up(md) {
          border: none;
          border-left: 1px solid $grey-3;
          border-right: 1px solid $grey-3;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .jumbotron {
      height: 350px;
      background-position: 50% top;
      background-size: auto;
    }

    #banner-text {
      width: $maxWidth;

      .headline {
        padding-left: 50%;
        padding-top: 2em;

        .headline-wrapper {
          padding: 0;
          background: none;
          box-shadow: none;
        }

        .jumbo-headline {
          font-size: rem-calc(75);
        }
      }

      .inspired-by {
        top: 320px;
        left: 0;
        color: $white;
      }

      .choice {
        margin-left: 0;
        max-width: none;
      }

      .or-divider-block {
        &::before,
        &::after {
          width: 1px;
          height: 24px;
          left: 17px;
        }

        &:before {
          top: -25px;
        }

        &:after {
          top: 25px;
        }
      }
    }
  }
}
