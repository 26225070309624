// stylelint-disable declaration-no-important, selector-list-comma-newline-after

//
// Horizontal rules
//

hr {
  border: 0;
  border-top: $hr-border-width solid $hr-border-color;
}

//
// Emphasis
//

small,
.small {
  font-size: $small-font-size;
  font-weight: $font-weight-normal;
}

mark,
.mark {
  padding: $mark-padding;
  background-color: $mark-bg;
}

//
// Lists
//

.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: $list-inline-padding;
  }
}
