#contact-a-rep {
  #masthead {
    background-color: $light-blue;

    h1 {
      opacity: 1;
      color: $teal;
    }
  }
}
