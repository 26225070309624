#sitemap {
  // General
  div ul {
    margin: 5px 0 0;
    padding: 0;

    li {
      margin: 0 0 5px;
      padding: 0;
    }

    a {
      color: $blue;
      font-family: $font-bold;

      &:hover {
        color: $blue;
      }
    }

    li::after {
      display: none;
    }

    &.inner-page-list li a {
      color: $body-color;
    }
  }

  // Parent ul
  div > ul > li {
    margin-bottom: 1em;
  }

  .list-indent {
    margin-left: 30px;
  }
}
