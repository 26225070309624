.container {
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 auto;
  max-width: $maxWidth;

  &-medium {
    max-width: $mediumWidth;
  }

  &-small {
    max-width: $smallWidth;
  }
}

a.link {
  font-family: $font-bold;
  color: $blue;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.icon {
  width: 50px;
  height: auto;
}

.icon-vertical {
  width: auto;
  height: 50px;
}

.icon-large {
  height: auto;
  width: 50px;

  @include media-breakpoint-up(md) {
    width: 110px;
  }
}

.stay-updated .button {
  border-color: #6bc8c7;
  height: rem-calc(32);
}

.checkbox-square {
  position: relative;

  &:before {
    content: "";
    height: rem-calc(30);
    width: rem-calc(30);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    border: 2px solid $grey-3;
  }

  &.checkmark:after {
    content: "";
    background: url("../images/checkmark.png");
    background-size: cover;
    width: 50px;
    height: rem-calc(40);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, calc(-50% - 8px));
  }
}

.sm\:text-center {
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
}
