$subnavOffset: $scrollMainHeaderH + $notificationHeight;
$notificationMobileNavH: $mobileNavHeight + $notificationHeight;
$notificationMainHeaderHmob: 110px;
$notificationMainHeaderH: 152px;

body.notification {
  #main-nav {
    @include media-breakpoint-down(md) {
      z-index: 100;
    }
  }
}

.cookie-policy {
  display: none;
}

body.notification .cookie-policy {
  width: 100%;
  background: $body-color;
  height: $cookiePolicyBannerH;
  color: $white;
  text-align: center;
  font-size: rem-calc(10);
  line-height: 1.3;
  display: flex;
  align-items: center;
  text-align: left;
  z-index: 4;

  @include media-breakpoint-up(md) {
    justify-content: center;
  }

  @include media-breakpoint-up(sm) {
    font-size: rem-calc(12);
  }

  a {
    text-decoration: underline;
    color: $white;
  }
  div {
    width: 100%;
    position: relative;
    padding-right: 47px;
    padding-left: 20px;
    @include media-breakpoint-up(md) {
      width: auto;
      padding-right: 37px;
    }

    .close-cookie {
      position: absolute;
      right: 20px;
      height: 20px;
      width: 20px;
      cursor: pointer;
      border: 0;
      top: 50%;
      transform: translate(0, -50%);

      @include media-breakpoint-up(md) {
        right: 0;
      }

      &::after,
      &::before {
        position: absolute;
        right: 8px;
        top: 0;
        content: " ";
        height: 15px;
        width: 1px;
        background-color: $white;
        cursor: pointer;
      }
      &::after {
        transform: rotate(45deg);
      }
      &::before {
        transform: rotate(-45deg);
      }
    }
  }
}

body.notification.scrolled .cookie-policy {
  top: 0;
}

body.notification #desktop-cookie-policy {
  display: flex;
}
