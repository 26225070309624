$grid-gap: 1em;
$grid-columns: (2, 3, 4, 5, 6, 8, 9, 10, 12);
$grid-start: 1;
$grid-end: 12;

.grid {
  column-gap: $grid-gap;
  display: grid;
  row-gap: $grid-gap;
}

.grid-align-center {
  align-items: center;
}

@mixin span($cols) {
  grid-column: span #{$cols} / span #{$cols};
}

@each $i in $grid-columns {
  .grid-cols-#{$i} {
    grid-template-columns: repeat($i, 1fr);
  }
}

@for $i from $grid-start through $grid-end {
  .col-span-#{$i} {
    @include span($i);
  }
}

@each $size in ("md", "lg") {
  @include media-breakpoint-up(($size)) {
    .#{$size}\: {
      @for $i from $grid-start through $grid-end {
        // grid column spans
        &col-span-#{$i} {
          @include span($i);
        }

        // grid columns
        @each $i in $grid-columns {
          &grid-cols-#{$i} {
            grid-template-columns: repeat($i, 1fr);
          }
        }
      }
    }
  }
}
