#main-nav {
  background-color: rgba(255, 255, 255, 0.95);
  width: 100%;
  z-index: 4; // Modals set to 5
  box-shadow: $shadow;
  top: 0;
  position: sticky;

  .nav-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    flex-wrap: wrap;
  }

  @include media-breakpoint-up(md) {
  }
}

@include media-breakpoint-down(sm) {
  #main-nav {
    position: relative;
  }

  body.notification #primary-nav .navigation .main-nav {
    top: 180px;
  }

  body.scrolled #main-nav {
    top: 0;
  }
}

body.mobile-menu {
  overflow: hidden;
}

#primary-nav {
  position: relative;
  width: 100%;

  @include media-breakpoint-down(sm) {
    height: $mobileNavHeight;
  }

  .logo {
    img {
      width: 134px;
      height: 33px;
    }

    @include media-breakpoint-up(md) {
      margin-right: 40px;

      img {
        height: 40px;
        width: 163px;
      }
    }
  }

  .navigation {
    @include media-breakpoint-up(md) {
      flex-grow: 1;
      position: relative;
      height: 100%;
    }

    &.active .main-nav {
      height: 50vh;
    }

    .main-nav {
      position: absolute;
      left: 0;
      width: 100%;
      height: 0;
      align-items: stretch;
      justify-content: flex-start;
      flex-direction: column;
      box-shadow: 0 8px 7px -3px $dark-transparent;
      line-height: 1.3em;
      transition: all 0.3s ease-in-out;
      z-index: 100;
      background-color: $light-green;
      overflow: hidden;

      @include media-breakpoint-down(sm) {
        padding: 0 2em;
        top: 140px;
        position: fixed;
        overflow-y: scroll;
        text-align: center;

        > li {
          border-bottom: 2px solid $grey-3;
        }
      }

      @include media-breakpoint-up(md) {
        display: flex;
        height: 100%;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        flex-grow: 1; // set all links to equal widths
        position: relative;
        top: auto;
        left: auto;
        background-color: transparent;
        box-shadow: none;

        li {
          display: flex;
          height: 100%;
          width: 100%; // equal widths
        }
      }

      .has-subnav {
        position: relative;

        &::after {
          content: "^";
          position: absolute;
          top: 17px;
          margin-left: 10px;

          @include media-breakpoint-up(md) {
            display: none;
          }
        }

        &.expanded::after {
          top: 10px;
          transform: rotate(180deg);
        }
      }

      .subnav {
        height: 0;
        overflow: hidden;

        a {
          font-size: rem-calc(14);
          text-transform: uppercase;
        }

        &.expanded {
          height: auto;
        }

        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }

    a {
      display: block;
      width: 100%; // equal widths
      align-items: center;
      color: $blue;
      font-family: $font-medium;
      padding: 1em;
      font-size: rem-calc(16);
      justify-content: left;

      @include media-breakpoint-up(md) {
        display: flex;
        min-height: 0;
        text-align: center;
        padding: 5px;
        letter-spacing: rem-calc(0.5);
        justify-content: center;

        &.active,
        &:hover {
          background: $teal;
          color: $white;
        }
      }
    }
  }
}

#secondary-nav {
  background-color: $grey-2;
  font-size: 0.8em;
  line-height: 0.8em;
  height: $secondaryNavHeight;
  box-shadow: $shadow;

  @include media-breakpoint-up(md) {
    height: $secondaryNavHeightDesktop;
  }

  .nav a {
    color: $grey-4;
    font-size: rem-calc(14);
    padding: 0.5em 0.75em 0.5em 0;

    @include media-breakpoint-up(md) {
      font-size: rem-calc(11);
      padding: 0;
    }

    &:hover,
    &:focus {
      span {
        color: $teal;
        text-decoration: underline;
      }
    }
  }

  .intended-for-text {
    color: $grey-4;
    font-size: rem-calc(10);
    letter-spacing: rem-calc(0.2);
    line-height: 1.4em;
    display: flex;

    @include media-breakpoint-up(md) {
      width: 130px;
    }
  }

  .navigation {
    align-items: center;
    justify-content: space-between;
    width: 100%;
    order: 1;
    margin-top: 5px;

    @include media-breakpoint-up(md) {
      height: $secondaryNavHeightDesktop;
      order: 0;
      margin-top: 0;
      width: calc(83% - 130px - 145px);
    }
  }
}

#navbar-toggler {
  width: 30px;
  height: 50px;
  padding: 0;
  margin-left: auto;

  span {
    display: block;
    height: 2px;
    width: 100%;
    background-color: $grey-4;
    margin: 6px auto;
    transition: 0.3s;
  }

  &.active {
    span:nth-child(1) {
      transform: rotate(45deg);
      margin-top: 0;
    }

    span:nth-child(2) {
      opacity: 0;
    }

    span:nth-child(3) {
      transform: rotate(-45deg);
      margin-top: -16px;
    }
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
}

@include media-breakpoint-up(md) {
  body,
  body.scrolled {
    #main-nav {
      &.box-shadow {
        box-shadow: 0 8px 7px -3px $dark-transparent;
      }
    }

    #primary-nav {
      height: $mainNavHeight;

      .navigation a {
        font-size: rem-calc(14);
        line-height: 1.3;
      }
    }
  }
}

.notification #subnav {
  @include media-breakpoint-up(md) {
    top: $scrollMainHeaderH + $cookiePolicyBannerH;
  }
}

#subnav {
  background: $grey-7;
  text-transform: uppercase;
  position: relative;
  z-index: 100;
  box-shadow: $shadow;

  @include media-breakpoint-up(md) {
    position: sticky;
    top: $scrollMainHeaderH;
  }

  .navigation {
    display: flex;
    justify-content: space-between;
    min-height: 49px;
    font-family: $font-medium;
    align-items: center;

    li {
      padding: 0.5em 1em;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }

    @include media-breakpoint-up(md) {
      justify-content: center;
    }

    a {
      color: $blue;
      display: block;
      text-align: center;
      padding: 0;
      font-size: rem-calc(14);

      @include media-breakpoint-up(md) {
        margin: 0 40px;

        &:hover,
        &.active {
          color: $body-color;
          position: relative;
          display: grid;

          &:after {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 50%;
            height: 4px;
            background: $green;
            width: 50%;
            transform: translate(-50%, 0);

            @include media-breakpoint-up(md) {
              width: 60px;
              bottom: -14px;
            }
          }
        }
      }
    }
  }
}

.cookie-policy-banner {
  background-color: $grey-4;
  color: $white;
  font-size: rem-calc(12);

  &.closed {
    height: 0px !important;
    opacity: 0;
  }

  .container {
    @include media-breakpoint-down(sm) {
      margin-top: 0rem !important;
      margin-bottom: 0rem !important;
    }
  }

  display: none;
  @include media-breakpoint-up(md) {
    display: block;
  }
}
