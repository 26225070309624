/// Grid system
//
// Generate semantic grid columns with these mixins.

@mixin make-container() {
  width: 100%;
  // padding-right: ($grid-gutter-width / 2);
  // padding-left: ($grid-gutter-width / 2);
  margin-right: auto;
  margin-left: auto;
}

// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

@mixin make-row() {
  display: flex;
  flex-wrap: wrap;
}
