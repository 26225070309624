#error404 {
  #masthead {
    background-color: $light-blue;

    h1 {
      opacity: 1;
      color: $teal;
    }
  }

  p {
    font-size: 33px;
    color: $teal;
    letter-spacing: 1.5px;
    margin-top: 70px;
    margin-bottom: 30px;
    line-height: 1.19;
    @include media-breakpoint-down(sm) {
      font-size: 16px;
      letter-spacing: 0px;
      margin-top: 0;
      line-height: 1.19;
    }
  }
}
